import classnames from 'classnames';
import get from 'lodash/get';
import identity from 'lodash/identity';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { QRCodeSVG } from 'qrcode.react';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Typist from 'react-typist';
import styled from 'styled-components';
import url from 'url';
import * as ApiActions from 'actions/api';
import logo_src from 'assets/Home/header__logo.svg';
import originalSharingUrl from 'assets/sharing.jpg';
import firstWalkSharingUrl from 'assets/sharing_first_walk.jpg';
import xylaAppStoreImage from 'assets/xyla/app_store_button.svg';
import xylaBootsImage from 'assets/xyla/boots4x.png';
import xylaCombinedLogos from 'assets/xyla/combined_logos.svg';
import xylaGooglePlayImage from 'assets/xyla/google_play_button.svg';
import xylaPhoneImage from 'assets/xyla/phone4x.png';
import analytics from 'utils/analytics';
import { generateFirebaseInviteQuery } from 'utils/firebase';
import platformDetector from 'utils/platform';
import { getFirstLetter, getFirstName } from 'utils/user';
import { useFirebaseEssentials } from 'routes/InvitePages/utils/firebase';
import useAdjustLink from 'utils/useAdjustLink';
import { makeOfferSelector, makeProfileSelector } from 'selectors/invites';
import '../styles/custom-firebase-page.scss';
import getVariant from '../utils/getVariant';
import inviteImageW1 from 'assets/Home/invite_images/w1.jpg';
import inviteImageW2 from 'assets/Home/invite_images/w2.jpg';
import inviteImageW3 from 'assets/Home/invite_images/w3.jpg';
const XYLA_COMPANY_CODE = 'qWyDqYVVi-z1tbB';
const messages = defineMessages({
    invite: '<strong>{name}</strong>{br} invites you to{nbsp}join',
    invite_offer: '{name} invites you{br} to join Sweatcoin app',
    offer: '40,000,000+ users are already getting rewards for their steps. Join them\u00a0too!',
    crowdfunding_offer: 'Change the world with your steps.',
    about: 'About campaign',
    join: 'Join {name} and convert your steps into help for this crowdfunding project.',
    button: 'Accept Invite',
    challenge_offer: 'Sweatcoin converts your steps into a currency to spend on cool products and services.',
    challenge_invite: '{name} invites you to join a Group Walking Challenge.{br} Hit the goal and win some prizes!',
    challenge_button: 'Join the challenge',
    xyla_welcome_title: 'Redeem your first 10 sweatcoins and get the reward',
    xyla_welcome_instruction_desktop: 'Scan the QR Code above with your phone and follow the steps',
    xyla_welcome_instruction_mobile: 'Tap below to download the{br}Sweatcoin app and start your health journey',
    xyla_download_button_title: 'Download app',
});
function useQuery() {
    return queryString.parse(useLocation().search);
}
var PageVariant;
(function (PageVariant) {
    PageVariant[PageVariant["Xyla"] = 0] = "Xyla";
    PageVariant[PageVariant["Default"] = 1] = "Default";
})(PageVariant || (PageVariant = {}));
const APPSTORE_LINK = 'https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427?mt=8';
const CustomFirebasePage = (props) => {
    const { match, location } = props;
    const { params } = match;
    const adjustLink = useAdjustLink();
    const variant = match.params.username === 'XYLA' ? PageVariant.Xyla : PageVariant.Default;
    const isExperimentalTracking = match.params.username === 'avatrodriguez';
    const storeLink = isExperimentalTracking
        ? adjustLink.IOS || adjustLink.ANDROID /* they are the same for now */
        : APPSTORE_LINK;
    const welcome = (function () {
        const segments = location.pathname.split('/');
        return segments[segments.length - 1].toLowerCase() === 'welcome';
    })();
    // Profile & meta
    const profileSelector = useMemo(makeProfileSelector, []);
    const profile = useSelector((state) => profileSelector(state, params));
    const avatar = get(profile, 'avatar.preview');
    const username = params.username;
    const name = getFirstName(profile);
    const firstLetter = getFirstLetter(profile);
    // Offer & meta & extended layout
    const extendedPageParams = pick(params, ['offer_id', 'challenge_id', 'group_id']);
    const offerSelector = useMemo(makeOfferSelector, []);
    const offer = useSelector((state) => offerSelector(state, params));
    const background = get(offer, 'images[0].cover'); // Crowdfunding sharing image
    const hasChallenge = params.challenge_id != null;
    const reimplementGetCopyLink = () => {
        const params = ['link', 'apn', 'isi', 'ibi', 'ius', 'ct', 'pt', '_icp'];
        const copyQuery = pick(mapValues({ ...firebaseQuery, _icp: 1 }, (value, key) => {
            return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
        }), params);
        return `${firebaseDynamicLinkDomain}?${queryString.stringify(copyQuery, {
            encode: false,
            sort: (m, n) => Number(params.indexOf(m) >= params.indexOf(n)),
        })}`;
    };
    const getCopyLink = variant === PageVariant.Xyla
        ? undefined // use default firebase function
        : reimplementGetCopyLink;
    const { firebaseData: { firebaseQuery: xylaFirebaseQuery, getRedirectLink: xylaGetRedirectLink }, tracking: { channel, platform, feature, campaign }, config, buttonsRefs: [inviteButton, appstoreButton, googleplayButton, xylaDownloadMobileRef], } = useFirebaseEssentials({
        refsTextFns: [getCopyLink, getCopyLink, getCopyLink, undefined],
        path: `/company/${XYLA_COMPANY_CODE}`,
        feature: 'company_invite',
        company_invite_code: XYLA_COMPANY_CODE,
        deeplink_path: `/company/${XYLA_COMPANY_CODE}`,
    });
    const { origin, firebaseDynamicLinkDomain } = config;
    const absoluteOriginalSharingUrl = url.resolve(origin, originalSharingUrl);
    // Page messages experiment
    const [actionsVisible, setActionsVisible] = useState(false);
    const query = useQuery();
    const customInviteMessage = query.msg;
    const achievementType = query.achievement;
    const absoluteFirstWalkSharingUrl = url.resolve(origin, firstWalkSharingUrl);
    const [shareVariant, variantCampaign] = getVariant(origin, true);
    const updatedCampaign = Number(shareVariant) > 0 ? variantCampaign : campaign;
    const sharingImageUrl = achievementType === 'first_walk' ? absoluteFirstWalkSharingUrl : absoluteOriginalSharingUrl;
    const inviteImages = [
        url.resolve(origin, inviteImageW1),
        url.resolve(origin, inviteImageW2),
        url.resolve(origin, inviteImageW3),
    ];
    const sharingImage = inviteImages[Number(shareVariant) - 1] || sharingImageUrl;
    const qrCodeLink = `${origin}${location.pathname}`;
    const firebaseQuery = variant === PageVariant.Xyla
        ? xylaFirebaseQuery
        : generateFirebaseInviteQuery(profile, config, {
            channel,
            feature,
            updatedCampaign,
            welcome,
            ...extendedPageParams,
            encodeLink: true,
        });
    const reimplementGetRedirectLink = () => {
        const visitorPlatform = getPlatform();
        if (visitorPlatform === 'iOS') {
            const redirectQuery = mapValues({ ...firebaseQuery, _fpb: '=' }, (value, key) => {
                return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
            });
            return `${firebaseDynamicLinkDomain}?${queryString.stringify(redirectQuery, { encode: false })}`;
        }
        if (visitorPlatform === 'Android') {
            const redirectQuery = mapValues(firebaseQuery, (value, key) => {
                return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
            });
            return `${firebaseDynamicLinkDomain}?${queryString.stringify(redirectQuery, { encode: false })}`;
        }
        return firebaseQuery.link;
    };
    const getRedirectLink = variant === PageVariant.Xyla ? xylaGetRedirectLink : reimplementGetRedirectLink;
    return (React.createElement("section", { className: classnames('custom-firebase-page', { 'custom-firebase-page_crowdfunding': offer }) },
        React.createElement(Helmet, { link: [
                {
                    rel: 'image_src',
                    href: sharingImage,
                },
            ], meta: [
                // {
                //   name: 'apple-itunes-app',
                //   content: `app-id=971023427${deeplink_path ? `, app-argument=${deeplink_path}` : ''}`
                // },
                { name: 'event-id', content: 'custom-firebase-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        page_type: 'invite_page',
                        username: username,
                        inviter_id: profile.id,
                        channel,
                        platform,
                        feature,
                        updatedCampaign,
                        ...extendedPageParams,
                    }, identity),
                },
                { property: 'og:image', content: sharingImage },
                { name: 'twitter:image', content: sharingImage },
                { name: 'twitter:card', content: 'summary_large_image' },
            ] }),
        offer ? (React.createElement(Helmet, { title: get(offer, 'title'), link: [{ rel: 'image_src', href: background }], meta: [
                { property: 'og:title', content: get(offer, 'title') },
                { property: 'og:description', content: get(offer, 'description') },
                { name: 'description', content: get(offer, 'description') },
                { name: 'twitter:description', content: get(offer, 'description') },
                { property: 'og:image', content: background },
                { name: 'twitter:image', content: background },
            ] })) : null,
        variant === PageVariant.Xyla && (React.createElement(XylaWrapper, null,
            React.createElement(XylaHeader, null,
                React.createElement(CombinedLogo, null)),
            React.createElement(XylaWelcomeContent, null,
                React.createElement(XylaPictures, null,
                    React.createElement(XylaBoots, null,
                        React.createElement(XylaBootsImg, null)),
                    React.createElement(XylaPhone, null,
                        React.createElement(XylaPhoneImg, null))),
                React.createElement(XylaDownloadInfo, null,
                    React.createElement(XylaMainTextWrapper, null,
                        React.createElement(XylaQRCodeContainer, { width: 128, height: 128 },
                            React.createElement(QRCodeSVG, { value: qrCodeLink })),
                        React.createElement(XylaMainTitle, null,
                            React.createElement(FormattedMessage, { ...messages.xyla_welcome_title })),
                        React.createElement(XylaDownloadDescription, null,
                            React.createElement(OnlyMobile, null,
                                React.createElement(FormattedMessage, { ...messages.xyla_welcome_instruction_mobile })),
                            React.createElement(OnlyDesktop, null,
                                React.createElement(FormattedMessage, { ...messages.xyla_welcome_instruction_desktop })))),
                    React.createElement(OnlyDesktop, null,
                        React.createElement(XylaDownloadButtonsGroup, null,
                            React.createElement(XylaStore, null,
                                React.createElement(XylaAppStoreImg, null)),
                            React.createElement(XylaStore, null,
                                React.createElement(XylaGooglePlayImg, null)))),
                    React.createElement(OnlyMobile, null,
                        React.createElement(MobileButtonContainer, null,
                            React.createElement(XylaDownloadMobileButton, { onClick: handleClick, href: xylaFirebaseQuery?.link || '', ref: xylaDownloadMobileRef },
                                React.createElement(FormattedMessage, { ...messages.xyla_download_button_title })))))))),
        variant === PageVariant.Default && (React.createElement(React.Fragment, null,
            background ? (React.createElement("div", { className: "custom-firebase-page__mood", style: background ? { backgroundImage: `url(${background})` } : null },
                React.createElement("div", { className: "custom-firebase-page__mood__gradient" }))) : (React.createElement("div", { className: "custom-firebase-page__base" },
                React.createElement("div", { className: "custom-firebase-page__base__mountains" }))),
            !offer ? (React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src }))) : null,
            React.createElement("section", { className: "custom-firebase-page__container" },
                React.createElement("div", { className: "custom-firebase-page__top" },
                    React.createElement("div", { className: "custom-firebase-page__social" },
                        React.createElement("div", null,
                            offer ? (React.createElement("i", { className: "custom-firebase-page__social__logo icon-logo custom-firebase-page__social__logo--animated" })) : null,
                            React.createElement("div", { className: "custom-firebase-page__social__avatar", style: !avatar ? { border: '1px solid #fff' } : null },
                                React.createElement("div", { className: "custom-firebase-page__social__avatar__pic", style: avatar ? { backgroundImage: `url(${avatar})` } : null }),
                                firstLetter)),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { ...(hasChallenge ? messages.challenge_invite : getInviteMessage()), values: { name } })))),
                React.createElement("div", { className: "custom-firebase-page__cta" },
                    offer || customInviteMessage ? (React.createElement("h1", { className: "custom-firebase-page__heading custom-firebase-page__heading--console" },
                        React.createElement(ConsoleBullet, null, ">"),
                        customInviteMessage ? (getTypistContent(customInviteMessage)) : (React.createElement(FormattedMessage, { ...getOfferMessage() }, (message) => getTypistContent(message))))) : null,
                    React.createElement("div", { className: "custom-firebase-page__action", style: { opacity: actionsVisible || !offer ? 1 : 0 } },
                        React.createElement("a", { className: "custom-firebase-page__action__link", href: storeLink, ref: inviteButton, onClick: handleClick },
                            React.createElement(FormattedMessage, { ...(hasChallenge ? messages.challenge_button : getButtonMessage()) })))),
                offer && (React.createElement("div", { className: "custom-firebase-page__bottom" },
                    React.createElement("h2", { className: "custom-firebase-page__bottom__campaign-title" }, offer.title),
                    React.createElement("div", { className: "custom-firebase-page__bottom__join-text" },
                        React.createElement(FormattedMessage, { ...messages.join, values: { name } })),
                    React.createElement("div", { className: "custom-firebase-page__bottom__about" },
                        React.createElement(FormattedMessage, { ...messages.about })),
                    React.createElement("div", { className: "custom-firebase-page__bottom__campaign-description" }, offer.description))),
                !offer ? (React.createElement("div", { className: "custom-firebase-page__bottom custom-firebase-page__bottom__apps" },
                    React.createElement(Apps, null,
                        React.createElement(App, null,
                            React.createElement("a", { href: storeLink, ref: appstoreButton, onClick: handleClick },
                                React.createElement(AppButton, null,
                                    React.createElement("i", { className: 'icon-appstore' }))),
                            React.createElement(AppRating, { opacity: avatar ? 1 : 0.6 },
                                "4.6",
                                React.createElement(AppStars, null,
                                    React.createElement(AppStarsContent, null, "\u2606\u2606\u2606\u2606\u2606"),
                                    React.createElement(AppStarsActive, { rating: 4.6 }, "\u2605\u2605\u2605\u2605\u2605")))),
                        React.createElement(App, null,
                            React.createElement("a", { href: APPSTORE_LINK, ref: googleplayButton, onClick: handleClick },
                                React.createElement(AppButton, null,
                                    React.createElement("i", { className: 'icon-googleplay' }))),
                            React.createElement(AppRating, { opacity: avatar ? 1 : 0.6 },
                                "4.4",
                                React.createElement(AppStars, null,
                                    React.createElement(AppStarsContent, null, "\u2606\u2606\u2606\u2606\u2606"),
                                    React.createElement(AppStarsActive, { rating: 4.4 }, "\u2605\u2605\u2605\u2605\u2605"))))))) : null)))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track('CustomFirebasePage', 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            updatedCampaign,
            ...extendedPageParams,
        }, identity));
        window.location = getRedirectLink();
    }
    function getTypistContent(message) {
        return (React.createElement(TypistContainer, null,
            React.createElement(TypistPlaceholder, null,
                message,
                "_"),
            React.createElement(TypistContent, { startDelay: 1500, avgTypingDelay: 40, stdTypingDelay: 0, onTypingDone: handleTypingDone, cursor: {
                    show: true,
                    blink: true,
                    element: '_',
                    hideWhenDone: false,
                } }, message)));
    }
    function handleTypingDone() {
        setActionsVisible(true);
    }
    function getInviteMessage() {
        return offer ? messages.invite_offer : messages.invite;
    }
    // Golf experiment message
    function getButtonMessage() {
        return messages.button;
    }
    function getOfferMessage() {
        if (hasChallenge) {
            return messages.challenge_offer;
        }
        else if (offer) {
            return messages.crowdfunding_offer;
        }
        else {
            return messages.offer;
        }
    }
    function getUserAgent() {
        let userAgent = '';
        if (process.browser) {
            userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        }
        else {
            const request = get(props, 'location.state.request');
            if (request) {
                userAgent = request.headers['user-agent'];
            }
        }
        return userAgent;
    }
    function getPlatform() {
        return platformDetector(getUserAgent());
    }
};
CustomFirebasePage.fetchData = ({ match: { params } }, { dispatch }) => {
    return Promise.all([
        dispatch(ApiActions.searchUserByUsername(params.username)),
        params.offer_id ? dispatch(ApiActions.prefetchOffer(params.offer_id)) : null,
    ]);
};
const Logo = styled.img `
  position: relative;
  width: 156px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
  margin: 10px 0 27px 28px;
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const ConsoleBullet = styled.span `
  position: absolute;
`;
const TypistContainer = styled.div `
  position: relative;
  margin-left: 1em;
`;
const TypistPlaceholder = styled.div `
  visibility: hidden;
  overflow-wrap: break-word;
`;
const TypistContent = styled(Typist) `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow-wrap: break-word;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
  }
`;
const Apps = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const App = styled.div `
  text-align: left;

  &:last-child {
    margin-left: 15px;
  }
`;
const AppButton = styled.span `
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  display: inline-block;
  font-size: 19px;
  padding: 5px 10px 4px;
`;
const AppRating = styled.div `
  font-size: 11px;
  opacity: ${(props) => props.opacity};
  margin-top: 0.8em;
  margin-left: 0.7em;
`;
const AppStars = styled.span `
  position: relative;
  margin-left: 0.9em;
  letter-spacing: 0.2em;
`;
const AppStarsContent = styled.span `
  margin-right: -0.1em;
`;
const AppStarsActive = styled.span `
  position: absolute;
  white-space: nowrap;
  left: 0;
  overflow: hidden;
  width: ${(props) => Math.round((props.rating / 5) * 100)}%;
`;
const m = '1260px';
const l = '1440px';
const s = '560px';
const XylaWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(180deg, #7834ff 0%, #6742ce 50.5%, #18077a 99.5%);
  color: #ffffff;
`;
const XylaHeader = styled.header `
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: ${m}) {
    padding: 1.5rem 0;
  }

  @media (max-width: ${m}) {
    max-width: 100%;
    padding: 1.5rem;
    margin: 0;
  }
`;
const CombinedLogo = styled.img.attrs({
    src: xylaCombinedLogos,
}) `
  max-height: 60px;
  object-fit: contain;

  @media (max-width: ${m}) {
    max-width: 100%;
  }
`;
const XylaWelcomeContent = styled.div `
  flex: 0.7;
  display: flex;
  width: 100%;
  max-width: 2000px;
  align-self: center;

  @media (max-width: ${m}) {
    flex-direction: column;
    justify-content: center;
  }
`;
const XylaPictures = styled.div `
  display: flex;
  flex: 0.6;
  align-items: center;

  @media (max-width: ${m}) {
    padding-right: 2rem;
  }
`;
const XylaBoots = styled.div `
  display: flex;
  flex: 0.5;
`;
const XylaBootsImg = styled.img.attrs({
    src: xylaBootsImage,
}) `
  object-fit: contain;
  height: auto;
  max-width: 100%;
`;
const XylaPhone = styled.div `
  flex: 0.5;

  @media (max-width: ${m}) {
    text-align: center;
  }
`;
const XylaPhoneImg = styled.img.attrs({
    src: xylaPhoneImage,
}) `
  object-fit: contain;
  height: auto;
  max-width: 100%;
  height: 40vw;
  max-height: 700px;

  @media (max-width: ${m}) {
    min-width: 100%;
    height: 560px;
  }

  @media (max-width: ${s}) {
    height: auto;
  }
`;
const XylaMainTitle = styled.h1 `
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 700;
  padding-right: 1.5rem;

  @media (max-width: ${m}) {
    padding-right: 0;
  }

  @media (max-width: ${s}) {
    margin-top: 1.5rem;
    font-size: 1.125rem;
  }
`;
const XylaDownloadInfo = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.4;

  @media all and (max-width: ${m}) {
    align-items: center;
    margin-bottom: 4rem;
  }
`;
const XylaMainTextWrapper = styled.div `
  @media (max-width: ${l}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  @media (max-width: ${m}) {
    align-items: center;
    text-align: center;
  }
  @media (max-width: 420px) {
    padding: 0 1rem;
  }
`;
const XylaStore = styled.span ``;
const XylaDownloadButtonsGroup = styled.div `
  margin-top: 1.5rem;

  ${XylaStore} + ${XylaStore} {
    margin-left: 12px;
  }
`;
const XylaAppStoreImg = styled.img.attrs({
    src: xylaAppStoreImage,
}) ``;
const XylaGooglePlayImg = styled.img.attrs({
    src: xylaGooglePlayImage,
}) ``;
const XylaDownloadMobileButton = styled.a `
  margin-top: 1rem;
  display: flex;
  text-align: center;
  margin: 1.5rem auto 0;
  justify-content: center;
  max-width: 440px;
  padding: 0.75rem 0.5rem;
  background: #ffffff;
  font-size: 0.75rem;
  color: #000000 !important;
  font-weight: 700;
  text-decoration: none;
  border-radius: 16px;

  @media (max-width: ${s}) {
    margin-top: 1.5rem;
  }
`;
const XylaDownloadDescription = styled.p `
  margin-top: 1rem;
  max-width: 370px;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: ${m}) {
    font-size: 0.75rem;
  }
`;
const XylaQRCodeContainer = styled.div `
  display: inline-flex;
  padding: 0.125rem;
  border-radius: 0.25rem;
  background: #ffffff;
  margin-bottom: 1rem;

  @media (max-width: ${m}) {
    display: none;
  }
`;
const OnlyMobile = styled.div `
  width: 100%;

  @media (min-width: ${m}) {
    display: none;
  }
`;
const OnlyDesktop = styled.div `
  display: none;
  @media (min-width: ${m}) {
    display: block;
  }
`;
const MobileButtonContainer = styled.div `
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 0 1rem;
  margin: 0;
`;
export default CustomFirebasePage;
